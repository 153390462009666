import React from 'react'
import { connect } from 'react-redux'
import './header.css'
import bipard_logo from '../../assests/image/bihar_01.jpg'
import { Link } from 'react-router-dom'
import Marquee from '../../components/marquee';

const Header = (props) => {
    return (
        <section id='header_layout'>
            <div className='header'>
                <div className="mobile_logo">
                    <div >
                        <a href="/">
                            <img src={bipard_logo} alt="bipard_logo" className="bipard_logo" />
                        </a>
                    </div>
                    {/* <div className='logo_text'>
                        <a href='/'>
                            <span className="cb-lab">BIPARD</span>
                        </a>
                    </div> */}
                </div>
                {
                    props.auth.auth?.token
                        ?
                        <>
                            <div className='header_right'>
                                <a href='/application'>
                                    <button >Hi, {props.auth.auth?.data?.name}</button>
                                </a>
                                <a href='/logout'>
                                    <button title='Already Registered Candidates ? Please Click Here To Login'>Logout</button>
                                </a>
                            </div>
                        </>
                        :
                        <div className='header_right'>
                            <a href='/register'>
                                <button title='If You Have Not Registered Yet Please Click Here for Registration.'>Register / परीक्षा हेतु पंजीकरण</button>
                            </a>
                            <a href='/login'>
                                <button title='Already Registered Candidates ? Please Click Here To Login'>Login / लॉगिन</button>
                            </a>
                        </div>

                }

            </div>
            {/* <Marquee
                title1="Departmental Examinations of 8th and 9th September has been Cancelled due to unforeseen reasons. A future date will be provided."
                // title2="Slots will be announced every two weeks for with training candidates."
                // title3="Training will be conducted with minimum of 25 candidates else next slot wil be alloted."
            /> */}
        </section>
    )
}


const mapStateToProps = (state) => {
    return {
        auth: state.Auth
    }
}

const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(Header);