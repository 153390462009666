import React from 'react'
import './refundpolicy.css'
const RefundPolicy = () => {
  return (
    <>
      <div className='container'>
        <div className='refund-policy'>
          <h1>Return & Refund Policy </h1>
          <h6>Thanks for shopping at BIPARD Exam Portal.</h6>
          <h6>If you are not entirely satisfied with your purchase, we're here to help. </h6>
          <b>Definitions</b>
          <p>For the purposes of this Return and Refund Policy: </p>
          <p> <b>Company </b>(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Bihar Institute of Public Administration and Rural Development, Walmi C ampus, Phulwarisharif, Patna, Bihar. </p>

          <p> <b>Goods  </b>refer to the items offered for sale on the Service. </p>

          <p> <b>Orders  </b>mean a request by You to purchase Goods from Us.</p>

          <p> <b>Service </b>refers to the Website.</p>

          <h6> Returns</h6>
          <p>
            You have 20calendar days to return an item from the date you received it.
            Your item needs to have the receipt or proof of purchase
          </p>
          <h6> Refunds</h6>
          <p>
            If your return is approved, we will initiate a refund to your credit card (or original method of payment). A refund of 50 % will be provided.
            You will receive the credit within a certain amount of days, depending on your card issuer's policies.
          </p>
          <h6> Contact Us </h6>
          <p>
            If you have any questions on how to return your item to us, contact us.
          </p>
        </div>
      </div>
    </>
  )
}

export default RefundPolicy