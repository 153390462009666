import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { getChapter } from "./api";

import './chapterpage.css'
const ChapterPage = () => {

    const { ...params } = useParams()
    const [data, setData] = useState([])

    useEffect(() => {
        if (params.code) {
            getData()
        }
    }, [])

    const getData = async () => {
        let data = await getChapter(params.code, params.lang)
        if (data.code) {
            setData(data.data)
        }
    }

    const open = (d) => {
        window.open(`/questions/${d.topic_code}`, "_blank")
    }

    return (
        <>
            <div className='container-fluid card'>
                <h3 className='question-title'>Choose Your Chapter</h3>
                <div className='chapter_container row'>
                    {

                        data.map((d) => (
                            <div onClick={() => open(d)} className="col-lg-3 col-xs-6">
                                <div className="small-box-chapter bg-teal">
                                    <div className="inner-chapter">
                                        <p className="chapter-topic-list" style={{ height: '70px', fontSize: '17px' }}>
                                            {d.topic_name}
                                            <strong>({d.total_count})</strong>
                                        </p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa fa-file-pdf"></i>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default ChapterPage