import React from 'react'
import { Link } from 'react-router-dom'
import './questionbank.css'
const QuestionBank = () => {
  return (
    <>
      <div className='container-fluid card'>
        <h3 className='text-center mb-5'>Choose Your Language</h3>
        <div className='questionbank'>
          <div className='questionbank_hindi'>हिन्दी</div>
          <Link className='link_router' to='/subject-page'>
            <div className='questionbank_english'>English</div>
          </Link>
        </div>
        <h5 className='text-start p-5'><b>Note :</b> Please note that Question Bank is being revised and getting updated continously. Therefore you are advised to visit the question bank page regularly.</h5>
      </div>
    </>
  )
}

export default QuestionBank