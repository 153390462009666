import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import Pagination from "react-js-pagination";
import fileDownload from 'js-file-download';

import { loaderEnd, loaderStart } from '../../components/loader';

import { getQuestion } from './api';
import Question from "./eachQuestion";
import './styles.css'
const limit = 100;
const QuestionBank = () => {

    const { ...params } = useParams()
    const [questions, setQuestions] = useState([])
    const [pageNo, setPageNo] = useState(0)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        getData()
    }, [pageNo])

    const getData = async () => {
        loaderStart()
        let res = await getQuestion(pageNo, params.topic);
        if (res) {
            setQuestions(res.data.questions)
            setTotal(res.data.count)
        }
        loaderEnd()
    }

    const exportData = async () => {
        loaderStart()
        let res = await getQuestion(pageNo, params.topic, true);
        let fileName = params.topic + Date.now() + '.pdf';
        fileDownload(res, fileName);
        loaderEnd()
    }

    const handleSelected = (page) => {
        setPageNo(page - 1)
    }

    return (
        <>
            <div className='container-fluid card'>
                <br />
                <h3 className='text-center mb-5'>All Questions</h3>
                <div className='mb-4 text-end'>
                    <button className='btn btn-primary' onClick={exportData}>Download Questions</button>
                </div>
                {
                    questions.map((q, i) => (
                        <Question question={q} index={(i + 1) + ((pageNo) * 100)} key={i} />
                    ))
                }
                <br />


                <div>
                    <Pagination
                        activePage={pageNo + 1}
                        itemsCountPerPage={limit}
                        totalItemsCount={total}
                        pageRangeDisplayed={3}
                        onChange={handleSelected}
                        itemClass="page-item"
                        linkClass="page-link"
                    />
                </div>
            </div>
        </>
    )
}

export default QuestionBank