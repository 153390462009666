import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import swal from 'sweetalert';
import './application-form.css'
import { loaderEnd, loaderStart } from '../../components/loader';
import { reReregister } from './api';
import { GetCadre } from '../../information/actions';
import { getDataAfterLogin } from '../../store/auth/actions';


import Wizard from '../Wizard';

const ApplicationForm = (props) => {

    const [state, setState] = useState({
        email: "",
        "mobile": "",
        "service": "",
        "service_cadre_code": ""
    })

    useEffect(() => {
        getDataCader()
        setState({
            email: props.allData.email,
            "mobile": props.allData.mobile,
        })
    }, [])

    const [allCadre, setAllCadre] = useState([])

    const getDataCader = async () => {
        let data = await GetCadre()
        setAllCadre(data.data)
    }

    const handleChange = (e, type) => {
        let _state = { ...state }
        _state[e.target.name] = e.target.value
        setState(_state)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        loaderStart()
        let res = await reReregister(state)
        // console.log(res);
        if (res.code == 1) {
            swal("Re-registration successful", "", "success")
                .then((value) => {
                    localStorage.setItem("BIPARD-registration", JSON.stringify({
                        data: res.data.data,
                        token: res.data.token
                    }))
                    props.getDataAfterLogin({
                        data: res.data.data,
                        token: res.data.token
                    })
                    props.toggle()
                });
        }
        else {
            swal(res.message, "", "error")
        }
        loaderEnd()
    }



    const updateDOB = (dob) => {
        if (dob) {
            return dob.split("T")[0]
        }
    }

    return (
        <>
            <form className="row g-3" onSubmit={handleSubmit}>
                <div id="applications" className='application-body application-form-box'>
                    <div className=''>
                        <center> <h4> Personal Details / व्यक्तिगत विवरण</h4></center>


                        <div className="row">
                            <label for="inputEmail3" className="col-sm-2 col-form-label">

                                Full Name / पूरा नाम :
                            </label>
                            <div className="col-sm-4">
                                <input
                                    type="text"
                                    name='name'
                                    value={props.allData.name}

                                    disabled={true}
                                    className="form-control"
                                    id="inputEmail3"
                                />
                            </div>

                            <label for="inputEmail3" className="col-sm-2 col-form-label">

                                Aadhar No/आधार संख्या
                            </label>
                            <div className="col-sm-4">
                                <input
                                    type="number"
                                    className="form-control"
                                    name='aadhar'
                                    value={props.allData.aadhar}
                                    disabled={true}
                                    id="inputEmail3" />
                            </div>
                        </div>
                        <div className="row  mt-3">

                            <label for="inputEmail3" className="col-sm-2 col-form-label">Date of Birth/जन्म तिथि:</label>
                            <div className="col-sm-4">
                                <input disabled={true} value={updateDOB(props.allData.dob)} type="date" className="form-control" id="inputEmail3" />
                            </div>
                        </div>

                        <div className="row mt-3">
                            <label for="inputEmail3" className="col-sm-2 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i> Registered Mobile Number/पंजीकृत मोबाइल नंबर</label>
                            <div className="col-sm-4">
                                <input
                                    type="number" required name="mobile" value={props.allData.mobile} onChange={(e) => handleChange(e)} className="form-control" id="inputEmail3" />
                            </div>
                            <label for="inputEmail3" className="col-sm-2 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i> Email / ईमेल:</label>
                            <div className="col-sm-4">
                                <input
                                    type="email"
                                    name='email'
                                    value={state.email}
                                    onChange={(e) => handleChange(e)}
                                    required
                                    className="form-control" id="inputEmail3" />
                            </div>
                        </div>


                        <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-2 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i> Service / सेवा:</label>
                            <div className="col-sm-4">
                                <select className="form-select" id="autoSizingSelect"
                                    name='service'
                                    value={state.service}
                                    onChange={(e) => handleChange(e)}
                                    required
                                >
                                    <option value="">Select Service</option>
                                    <option value="Gazetted">Gazetted</option>
                                    <option value="Non-Gazetted">Non-Gazetted</option>
                                </select>
                            </div>
                            <label for="inputEmail3" className="col-sm-2 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i>Service cadre
                            </label>
                            <div className="col-sm-4">
                                <select required name='service_cadre_code' className="form-select" id="autoSizingSelect" onChange={(e) => handleChange(e)} value={state.service_cadre_code} >
                                    <option value="" selected>Select Service Cadre </option>
                                    {
                                        allCadre.map((s) => (
                                            <option value={s.service_cader_code} >{s.description}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </div>
            </form >
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.Auth
    }
}

const mapDispatchToProps = { getDataAfterLogin }
export default connect(mapStateToProps, mapDispatchToProps)(ApplicationForm);