import { apiFunction } from "../../apiCall/function"

export const getAllSubjects = async (data) => {
  return await apiFunction("/form/subject-allotment-detail", "post", data, true, 'body')
}

export const getCenter = async (data) => {
  return await apiFunction("/form/center", "get", {}, true, 'body')
}

export const getAvailability = async (center, type = 0) => {
  let url = `/form/date/${type}/${center}`;

  return await apiFunction(url, "get", {}, true, 'body')
}

export const getSlots = async (center, type, date) => {
  let url = `/form/slots/${type}/${center}/${date}`;
  if (date)
    return await apiFunction(url, "get", {}, true, 'body')
}

export const updateAvailability = async (data) => {
  return await apiFunction(`/form/allotment/`, "post", data, true, null)
}

export const initiatePayment = async (amount, id, allotmentId) => {
  let data = {
    amount: amount * 100,
    hdnOrderID: id,
    trnRemarks: "",
    allotmentId: allotmentId,
  }
  return await apiFunction(`form/payment`, "post", data, true, 'body')
}

export const cancelExam = async (user, exam) => {
  console.log(user, exam);
  return await apiFunction(`form/slot/${user}/${exam}`, "delete", {}, true, null)
}

export const getCenterPerSubject = async (code) => {
  return await apiFunction("/form//check-subject-center?subject_code=" + code, "get", {}, true, 'body')
}
