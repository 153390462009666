import axios from "axios";
import { getDataFromStore } from "../../store/getStore"


export const getToken = () => {
    let auth = getDataFromStore("Auth");
    // console.log(auth.auth);
    if (auth.auth?.token) {
        return auth.auth?.token
    }
    return '';
}

export const apiFunction = async (url, method, postData, token, extraConfig) => {

    url = process.env.REACT_APP_API_URL + url
    let config = {
        method: method,
        url: url,
        data: postData ? postData : {},
    };

    if (token) {
        let token = getToken();
        // console.log(token)
        config = {
            ...config,
            headers: { 'x-access-token': `${token}` },

        };
    }
    if (extraConfig == "blob") {
        config = {
            ...config,
            responseType: 'blob',
        }
    }
    else if (extraConfig == "body") {
        config = {
            ...config,
            headers: {
                ...config.headers,
                "content-type": "application/x-www-form-urlencoded",
            },
        };
    }
    else if (extraConfig == "formData") {
        config = {
            ...config,
            headers: {
                ...config.headers,
                "content-type": "multipart/form-data",
            },
        };
    }
    // console.log(config);

    let data;

    await axios({ ...config })
        .then((res) => {
            data = res.data
        })
        .catch((err) => {
            // console.log(err.response.data.message);
            // console.log(err);
            data = {
                ...err.response.data,
                status: false,
            };
        });
    // console.log(data);
    return data;
};


export const apiFunctionGeneric = async (url, method, postData, token, extraConfig) => {

    url = process.env.REACT_APP_RESULT + url
    let config = {
        method: method,
        url: url,
        data: postData ? postData : {},
    };

    if (token) {
        let token = getToken();
        // console.log(token)
        config = {
            ...config,
            headers: { 'x-access-token': `${token}` },

        };
    }
    if (extraConfig == "blob") {
        config = {
            ...config,
            responseType: 'blob',
        }
    }
    else if (extraConfig == "body") {
        config = {
            ...config,
            headers: {
                ...config.headers,
                "content-type": "application/x-www-form-urlencoded",
            },
        };
    }
    else if (extraConfig == "formData") {
        config = {
            ...config,
            headers: {
                ...config.headers,
                "content-type": "multipart/form-data",
            },
        };
    }

    // console.log(config);


    let data;

    await axios({ ...config })
        .then((res) => {
            data = res.data
        })
        .catch((err) => {
            // console.log(err.response.data.message);
            // console.log(err);
            data = {
                ...err.response.data,
                status: false,
            };
        });
    // console.log(data);
    return data;
};