import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux';
import swal from 'sweetalert';
import './application-form.css'
import { BounceLoader } from 'react-spinners';
import { loaderEnd, loaderStart } from '../../components/loader';
import { getApplication, updateApplication } from './api';
import { dateStandard } from "../../shared/dateFn";
// import { departments } from "./constants";
import { GetCadre, GetDepartmentData } from '../../information/actions';
import { checkUrl } from "../../shared/strings"
import { checkStatus } from "../../shared/commonFunction";


import Wizard from '../Wizard';

const ApplicationForm = (props) => {
    const navigate = useNavigate();
    const override = {
        display: "block",
        margin: "0 auto",
        borderColor: "red",
        height: '80vh'
    };
    const [check, setCheck] = useState(false)

    const [disabled, setDisabled] = useState(false)
    const [status, setStatus] = useState(false)

    const [state, setState] = useState({
        name: "",
        email: "",
        gender: "",

        designation: "",
        id_proof: "",
        aadhar: "",

        service: "",
        category: "",
        department: "",
        hrms_id: "",
        office_address: "",
        other_details: "",
        gpr_id: "",

        photo: "",
        signature: "",
        proof: "",
        formUpdated: 1
    })

    useEffect(() => {
        getData()
        getDepartment()
        getDataCader()
    }, [])

    const [allCadre, setAllCadre] = useState([])
    const [departments, setDepartments] = useState([])

    const getDataCader = async () => {
        let data = await GetCadre()
        setAllCadre(data.data)
    }

    const getDepartment = async () => {
        let data = await GetDepartmentData()
        setDepartments(data.data)
    }
    let getData = async (afterPrint) => {
        loaderStart()
        let res = await getApplication();
        // console.log(res);
        if (res.code == 1) {
            if (res.data.status == "INACTIVE") {
                navigate("/application")
            }
            let data = {
                ...res.data,
                photoImg: res.data.passport,
                signatureImg: res.data.signature,
                proofImg: res.data.proof,
            }
            delete data.passport;
            delete data.signature;
            delete data.proof;
            // console.log(data);
            if (afterPrint) {
                setState({ ...data, formUpdated: state.formUpdated + 1 })
                setTimeout(() => {
                    Print()
                    window.location.reload()
                }, 1000)
            }
            else {
                setState({ ...data })
            }

            let _status = checkStatus({
                is_approved: data.is_approved,
                document: res.data.document,
                passport: data.passport,
            }, "document")

            setDisabled(_status == "new" ? false : true)
            if (res.data.passport) {
                setStatus(true)
            }

            if (afterPrint) {

            }
            // setDisabled(true)
        }
        loaderEnd()
    }

    useEffect(() => {
        // Print()
        // window.location.reload()
    }, [state.formUpdated])


    const handleChange = (e, type) => {
        // console.log(e.target.name, e.target.value, e.target.type);
        let _state = { ...state }
        if (e.target.type === "file") {
            _state[e.target.name] = e.target.files[0]
        }
        else {
            _state[e.target.name] = e.target.value
        }
        // console.log(_state);
        setState(_state)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (disabled) {
            navigate('/step2')
        }
        else {
            loaderStart()
            // console.log(state);
            let formData = new FormData();
            for (let [key, val] of Object.entries(state)) {

                if (val)
                    formData.append(key, val);
            }
            if (!state.photo) {
                formData.append("photo", state.photoImg);
            }
            if (!state.signature) {
                formData.append("signature", state.signatureImg);
            }
            if (!state.proof) {
                formData.append("proof", state.proofImg);
            }
            let res = await updateApplication(formData)
            // console.log(res);
            if (res.code == 1) {
                swal("Application Form Saved", "अग्रसारण करने में आपको फिलहाल 1 - 2  दिन लग सकता है।  अंतः अभी आप SignOut  कर ले और जब आपके नियंत्री पदाधिकारी से अग्रसारित करा ले तब पुनः विभागीय परीक्षा Portal पर Login कर उक्त अग्रसारित आवेदन पत्र को अपलोड करें |  ", "success")
                    .then((value) => {
                        getData(true)
                    });
            }
            else {
                swal(res.message, "", "error")
            }
            loaderEnd()
        }
    }

    const handleRedirect = () => {
        if (check) {
            navigate('/step2')
        }
        else {
            swal("Please check the checkbox to proceed.", "", "error")

        }
    }

    const Print = () => {
        //console.log('print');  
        let printContents = document.getElementById('application-format').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }

    const [inputData, setInputData] = useState({
        fullname: ''
    })
    function changeHandle(e) {
        setInputData({ ...inputData, [e.target.name]: e.target.value })
    }
    let { fullname } = inputData

    const showalert = () => {
        swal("Application Form Saved", "अग्रसारण करने में आपको फिलहाल 1 - 2  दिन लग सकता है।  अंतः अभी आप SignOut  कर ले और जब आपके नियंत्री पदाधिकारी से अग्रसारित करा ले तब पुनः विभागीय परीक्षा Portal पर Login कर उक्त अग्रसारित आवेदन पत्र को अपलोड करें |  ", "success")
            .then((value) => {
                Print()
                window.location.reload()
            });
    }

    // console.log(props.auth.auth);

    const updateDOB = (dob) => {
        if (dob) {
            return dob.split("T")[0]
        }
    }


    const getCadre = (code) => {
        let item = allCadre.filter((f) => f.service_cader_code == code)
        return item.length ? item[0].description : ""
    }
    // console.log(state);

    return (
        <>
            <form className="row" onSubmit={handleSubmit}>
                <div className="card application-box">
                    <Wizard
                        application={status}
                        title="Departmental Examination Form"
                        redirect='/application'
                    />


                    <p align="right"><i style={{ color: 'red', fontSize: '16px' }} s>Fields marked with (*) are mandatory</i></p>
                    <div id="applications" className='application-body application-form-box'>

                        {(state?.last_rejection_reason && state?.is_approved == 2) && <div style={{ minHeight: 0 }} className='disqualified'>
                            Your application has been rejected<br />
                            Reason: {state?.last_rejection_reason}sfdsfsdfs
                        </div>}

                        <div className='card application-form'>
                            <center> <h4> Personal Details / व्यक्तिगत विवरण</h4></center>
                            <div className="row  mt-3">
                                <label for="inputEmail3" className="col-sm-2 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i> Registered Mobile Number/पंजीकृत मोबाइल नंबर</label>
                                <div className="col-sm-4">
                                    <input
                                        disabled={true}
                                        type="number" value={state.mobile} className="form-control" id="inputEmail3" />
                                </div>
                                <label for="inputEmail3" className="col-sm-2 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i>Date of Birth/जन्म तिथि:</label>
                                <div className="col-sm-4">
                                    <input disabled={true} value={updateDOB(props.auth.auth?.data?.dob)} type="date" className="form-control" id="inputEmail3" />
                                </div>
                            </div>

                            <div className="row">
                                <label for="inputEmail3" className="col-sm-2 col-form-label">
                                    <i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i>
                                    Full Name / पूरा नाम :
                                </label>
                                <div className="col-sm-4">
                                    <input
                                        type="text"
                                        name='name'
                                        value={state.name}
                                        onChange={(e) => handleChange(e)}
                                        required
                                        disabled={true}
                                        className="form-control"
                                        id="inputEmail3"
                                    />
                                </div>

                                <label for="inputEmail3" className="col-sm-2 col-form-label">
                                    <i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i>
                                    Aadhar No/आधार संख्या
                                </label>
                                <div className="col-sm-4">
                                    <input
                                        type="number"
                                        className="form-control"
                                        name='aadhar'
                                        value={props.auth.auth?.data?.aadhar}
                                        disabled={true}
                                        onChange={(e) => handleChange(e)}
                                        required
                                        id="inputEmail3" />
                                </div>
                            </div>

                            <div className="row mb-3 mt-3">
                                <label for="inputEmail3" className="col-sm-2 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i> Email / ईमेल:</label>
                                <div className="col-sm-4">
                                    <input
                                        type="email"
                                        name='email'
                                        value={state.email}
                                        onChange={(e) => handleChange(e)}
                                        required
                                        disabled
                                        className="form-control" id="inputEmail3" />
                                </div>
                                <label for="inputEmail3" className="col-sm-2 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i> Id Proof / पहचान प्रमाण:</label>
                                <div className="col-sm-4">
                                    <select
                                        name='id_proof'
                                        value={state.id_proof}
                                        onChange={(e) => handleChange(e)}
                                        required
                                        disabled={disabled}
                                        className="form-select" id="autoSizingSelect">
                                        <option value=""> Select Id Proof / पहचान प्रमाण</option>
                                        <option value="PAN No.">PAN No.</option>
                                        <option value="Aadhar No.">Aadhar No.</option>
                                    </select>
                                </div>
                            </div>

                            <div className="row mb-3 mt-3 ">
                                <label for="inputEmail3" className="col-sm-2 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i> Gender / लिंग</label>
                                <div className="col-sm-4 ">
                                    <div className='radio-group '>
                                        <input
                                            name='gender'
                                            value={"M"}
                                            checked={state.gender === "M" ? true : false}
                                            onChange={(e) => handleChange(e)}
                                            required
                                            disabled={disabled}
                                            type="radio" className="form-control form-check-input" id="inputEmail3" />
                                        <label className="form-check-label" for="gridRadios1">
                                            Male
                                        </label>
                                        <input
                                            name='gender'
                                            value={"F"}
                                            checked={state.gender === "F" ? true : false}
                                            onChange={(e) => handleChange(e)}
                                            required
                                            disabled={disabled}
                                            type="radio" className="form-control form-check-input" id="inputEmail3" />
                                        <label className="form-check-label" for="gridRadios1">
                                            Female
                                        </label><input
                                            name='gender'
                                            value={"T"}
                                            checked={state.gender === "T" ? true : false}
                                            onChange={(e) => handleChange(e)}
                                            required
                                            disabled={disabled}
                                            type="radio" className="form-control form-check-input" id="inputEmail3" />
                                        <label className="form-check-label" for="gridRadios1">
                                            Transgender
                                        </label>
                                    </div>
                                </div>
                                <label for="inputEmail3" className="col-sm-2 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i>Category / वर्ग:</label>
                                <div className="col-sm-4">
                                    <select
                                        name='category'
                                        value={state.category}
                                        onChange={(e) => handleChange(e)}
                                        required
                                        disabled={disabled}
                                        className="form-select" id="autoSizingSelect"
                                    >
                                        <option value=''>Select Category / वर्ग का चयन करें</option>
                                        <option value="Other">Other</option>
                                        <option value="SC/ST">SC/ST</option>
                                    </select>
                                </div>
                            </div>

                            <div className="row">
                                <label for="inputEmail3" className="col-sm-2 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i> Designation / पदनाम:</label>
                                <div className="col-sm-4">
                                    <input type="text" className="form-control" id="autoSizingSelect"
                                        name='designation'
                                        value={state.designation}
                                        disabled={disabled}
                                        onChange={(e) => handleChange(e)}
                                        required
                                    />
                                </div>
                                <label for="inputEmail3" className="col-sm-2 col-form-label"> <i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i>Department / विभाग:</label>
                                <div className="col-sm-4">
                                    <select className="form-select" id="autoSizingSelect"
                                        name='department'
                                        value={state.department}
                                        onChange={(e) => handleChange(e)}
                                        disabled={disabled}
                                        required
                                    >
                                        <option value=''>Select Department</option>
                                        {
                                            departments.map((d, i) => (
                                                <option value={d.name} key={i}>{d.name}</option>
                                            ))
                                        }
                                    </select>
                                    {/* <input type="text" className="form-control" id="autoSizingSelect"
                                        name='department'
                                        value={state.department}
                                        disabled={disabled}
                                        onChange={(e) => handleChange(e)}
                                        required
                                    /> */}

                                </div>
                            </div>

                            <div className="row mb-3 mt-3">
                                <label for="inputEmail3" className="col-sm-2 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i> Service / सेवा:</label>
                                <div className="col-sm-4">
                                    <select className="form-select" id="autoSizingSelect"
                                        name='service'
                                        value={state.service}
                                        disabled={disabled}
                                        onChange={(e) => handleChange(e)}
                                        required
                                    >
                                        <option value=''>Select Service</option>
                                        <option value="Gazetted">Gazetted</option>
                                        <option value="Non-Gazetted">Non-Gazetted</option>
                                    </select>
                                </div>
                                <label for="inputEmail3" className="col-sm-2 col-form-label">Service cadre
                                </label>
                                <div className="col-sm-4">
                                    <select disabled={disabled} required name='service_cadre_code' className="form-select" id="autoSizingSelect" onChange={(e) => handleChange(e)} value={state.service_cadre_code} >
                                        <option value="" selected>Select Service Cadre </option>
                                        {
                                            allCadre.map((s) => (
                                                <option value={s.service_cader_code} selected>{s.description}</option>
                                            ))
                                        }
                                    </select>
                                    {/* <input type="text" className="form-control" id="inputEmail3"
                                        name='hrms_id'
                                        value={state.service_cadre
                                        }
                                        disabled={true}

                                    /> */}
                                </div>
                            </div>

                            <div className="row mb-3 mt-3">
                                <label for="inputEmail3" className="col-sm-2 col-form-label">HRMS ID</label>
                                <div className="col-sm-4">
                                    <input type="text" className="form-control" id="inputEmail3"
                                        name='hrms_id'
                                        value={state.hrms_id}
                                        disabled={disabled}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                                <label for="inputEmail3" className="col-sm-2 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i> Other's Details</label>
                                <div className="col-sm-4">
                                    <select className="form-select" id="autoSizingSelect"
                                        name='other_details'
                                        value={state.other_details}
                                        onChange={(e) => handleChange(e)}
                                        disabled={disabled}
                                        required
                                    >
                                        <option value=''>Select Other Service</option>
                                        <option value="GPF">GPF</option>
                                        <option value="PRAN">PRAN</option>
                                    </select>
                                </div>

                            </div>
                            <div className="row mb-3 mt-3">
                                <label for="inputEmail3" className="col-sm-2 col-form-label"> GPF/PRAN ID</label>
                                <div className="col-sm-4">
                                    <input type="text" className="form-control" id="inputEmail3"
                                        name='gpr_id'
                                        value={state.gpr_id}
                                        disabled={disabled}
                                        onChange={(e) => handleChange(e)}

                                    />
                                </div>
                            </div>

                        </div>

                        <div className='card application-form'>
                            <center> <h4> Office Address / कार्यालय का पता</h4></center>

                            <div className="row  mt-3">
                                <label for="inputEmail3" className="col-sm-4 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i>  Name of the Office/ कार्यालय का नाम</label>
                                <div className="col-sm-8">
                                    <textarea
                                        name='office_address'
                                        value={state.office_address}
                                        onChange={(e) => handleChange(e)}
                                        required
                                        disabled={disabled}
                                        className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                </div>
                            </div>
                            {/* 
              <div className="row">
                <label for="inputEmail3" className="col-sm-2 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i>Post Office/डाक घर</label>
                <div className="col-sm-4">
                  <input type="text" className="form-control" id="inputEmail3" />
                </div>
                <label for="inputEmail3" className="col-sm-2 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i>State /राज्य</label>
                <div className="col-sm-4">
                  <select className="form-select" id="autoSizingSelect">
                    <option selected>Select State/राज्य चुनें</option>
                    <option value="1"></option>
                  </select>
                </div>
              </div> */}

                            {/* <div className="row mb-3 mt-3">
                <label for="inputEmail3" className="col-sm-2 col-form-label"> <i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i>District /जिला</label>
                <div className="col-sm-4">
                  <select className="form-select" id="autoSizingSelect">
                    <option selected>Select District/जिला चुनें</option>
                    <option value="1"></option>
                  </select>
                </div>
                <label for="inputEmail3" className="col-sm-2 col-form-label"> <i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i>PIN / पिन</label>
                <div className="col-sm-4">
                  <input type="text" className="form-control" id="inputEmail3" />
                </div>
              </div> */}

                        </div>

                        <div className='card application-form'>

                            <div className="row  mt-3">
                                <label for="inputEmail3" className="col-sm-4 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i>Candidate Passport size Photo/ पासपोर्ट साइज फोटो</label>
                                <div className="col-sm-6">
                                    <input
                                        name='photo'
                                        accept="image/*"
                                        onChange={(e) => handleChange(e)}
                                        disabled={disabled}
                                        required={state.photoImg ? false : true}
                                        type="file" className="form-control" id="inputEmail3"
                                    />
                                </div>
                                {
                                    state.photoImg
                                        ?
                                        <a target="_blank" href={checkUrl(state.photoImg) ? state.photoImg : process.env.REACT_APP_FILE_URL + state.photoImg}>View file</a>
                                        : null
                                }

                            </div>
                            <p align="center"><i style={{ color: 'red', fontSize: '16px' }} s>नोट - आवेदक का पासपोर्ट साइज फोटो jpg, jpeg, png मे होना चाहिए ।
                                फोटो का आकार 50 KB से 5MB के बीच होना चाहिए । आप अपने मोबाइल से भी फोटो ले सकतें है |</i></p>

                        </div>

                        <div className='card application-form'>

                            <div className="row  mt-3">
                                <label for="inputEmail3" className="col-sm-4 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i>Candidate Signature/ आवेदक का हस्ताक्षर :</label>
                                <div className="col-sm-6">
                                    <input
                                        name='signature'
                                        accept="image/*"
                                        onChange={(e) => handleChange(e)}
                                        disabled={disabled}
                                        required={state.signatureImg ? false : true}
                                        type="file" className="form-control" id="inputEmail3" />
                                </div>
                                {
                                    state.signatureImg
                                        ?
                                        <a target="_blank" href={checkUrl(state.signatureImg) ? state.signatureImg : process.env.REACT_APP_FILE_URL + state.signatureImg}>View file</a>
                                        : null
                                }
                            </div>
                            <p align="center"><i style={{ color: 'red', fontSize: '16px' }} s>नोट - आवेदक का हस्ताक्षर jpg, jpeg, png प्रारूप मे होना चाहिए ।
                                फोटो का आकार 4KB से 5MB के बीच होना चाहिए । आप अपने मोबाइल से भी फोटो ले सकतें है</i></p>

                        </div>

                        <div className='card application-form'>

                            <div className="row  mt-3">
                                <label for="inputEmail3" className="col-sm-4 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i>Candidate Id Proof / पहचान प्रमाण:</label>
                                <div className="col-sm-6">
                                    <input type="file" className="form-control" id="inputEmail3"
                                        name='proof'
                                        accept="image/*"
                                        disabled={disabled}
                                        onChange={(e) => handleChange(e)}
                                        required={state.proofImg ? false : true}
                                    />
                                </div>
                                {
                                    state.proofImg
                                        ?
                                        <a target="_blank" href={checkUrl(state.proofImg) ? state.proofImg : process.env.REACT_APP_FILE_URL + state.proofImg}>View file</a>
                                        : null
                                }
                            </div>
                            <p align="center"><i style={{ color: 'red', fontSize: '16px' }} s>नोट - आवेदक का पहचान प्रमाण: jpg, jpeg, png मे होना चाहिए ।
                                फोटो का आकार 50 KB से 5MB के बीच होना चाहिए । आप अपने मोबाइल से भी फोटो ले सकतें है |</i></p>

                        </div>

                        <div className='card application-form'>

                            <div className=" offset-sm-12">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="gridCheck1"
                                        required
                                        checked={check}
                                        onChange={() => setCheck(!check)}

                                    />
                                    <label className="form-check-label" for="gridCheck1">
                                        I hereby affirm that all the above given information is true to the best of my knowledge. If any of the details are found to be incorrect, my candidature may be rejected.
                                    </label>
                                </div>
                                <span><b>नोट -</b></span>
                                <li style={{ listStyleType: 'number' }}>आवेदक अनिवार्यतः अपने नियंत्री पदाधिकारी / कार्यालय प्रधान से ही आवेदन अग्रसारित कराकर अपलोड करेंगे |</li>
                                <li style={{ listStyleType: 'number' }}>नियंत्री पदाधिकारी / कार्यालय से आवेदन उपर दिये document अपलोड / step2 पर click कर अपलोड करें |</li><li style={{ listStyleType: 'number' }}>अपलोड करने के बाद ही आप वास्तविक परीक्षा दें सकते हैं |</li>
                            </div>


                        </div>

                    </div>
                    {
                        !disabled && <div className="d-grid gap-2">
                            <button className="btn btn-primary" type="submit"><i className="fa fa-print"></i>&nbsp;Save & Print</button>
                        </div>
                    }

                    <br />

                    {
                        disabled
                            ?
                            <button className="btn btn-primary" type="submit"><i className="fa fa-arrow-right"></i>&nbsp;Proceed to next Step</button>
                            :
                            <button onClick={() => handleRedirect()} className="btn btn-primary" type={"button"}><i className="fa fa-arrow-right"></i>&nbsp;Proceed to next Step</button>

                    }


                </div>
            </form >
            <div className='application-format container-fluid' id='application-format'>
                <div className='complete-application'>
                    <center>
                        <b>
                            <h5>बिहार लोक प्रशासन एवं ग्रामीण विकास संस्थान, बिहार</h5>
                            <h5>आवेदन पत्र </h5>
                            <p>( कृपया आवेदन पत्र भरते समय सूचनाओं को ध्यान से पढ़े )</p>
                        </b>
                    </center>
                    <div className='applicant-detail-box '>
                        <div className='applicant-detail '>
                            <ul style={{ listStyle: 'number' }}>
                                <li>Applicant Name/आवेदक का नाम :</li>
                                <li>Date Of Birth/जन्म तिथि :</li>
                                <li>Service/सेवा :</li>
                                <li>Class/श्रेणी:</li>
                                <li>Office Address/कार्यालय पता :</li>
                                <li>Designation/पद नाम :</li>
                                <li>Department/विभाग :</li>
                                {/* <li>Place/जगह :</li>
                            <li>District/जिला :</li>
                            <li>Employe Id: </li> */}
                            </ul>
                            <ul style={{ listStyle: 'none' }}>
                                <li>{state.name}</li>
                                <li style={{ color: "transparent" }}>.</li>
                                <li>{dateStandard(updateDOB(props.auth.auth?.data?.dob))}</li>
                                {/* <li style={{color:"transparent"}}>.</li> */}
                                <li>{getCadre(state.service_cadre_code)}</li>
                                <li>{state.category}</li>
                                <li>{state.office_address}</li>
                                <li>{state.designation} </li>
                                <li style={{ color: "transparent" }}>.</li>
                                <li>{state.department} </li>
                                {/* <li>{state.service}</li>
                            <li>{state.service} </li>
                            <li>{state.service}</li> */}
                            </ul>
                        </div>
                        <div className='applicant-photo p-5'>
                            <img style={{ width: 100, height: 100 }} src={!checkUrl(state.photoImg) ? process.env.REACT_APP_FILE_URL + state.photoImg : state.photoImg} alt='applicant_photo' />
                        </div>
                    </div>
                    <div className='appliaction_second_section'>
                        <p>Application Number/आवेदन संख्या: {props.auth.auth?.data?.aadhar} </p>
                        <p>Email Id:/ईमेल: {state.email}</p>
                        <p>Mobile Number/मोबाइल: {state.mobile} </p>
                    </div>

                    <div className='appliaction_memo_section'>
                        <p>Memo/ज्ञापांक &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>Date/दिनांक </span> </p>
                        <p>अग्रसारित नियंत्री पदाधिकारी / कार्यालय प्रधान का हस्ताक्षर ______________________</p>
                        <p>Designation/पद नाम ___________________ </p>
                        <p>Address/पता___________________ </p>
                        <p>Mobile Number/मोबाइल ___________________ </p>
                        <p style={{ textAlign: 'right' }}>Office Seal/कार्यालय मुहर</p>
                    </div>


                    <div className='notice_section'>
                        <p>Note/नोट </p>
                        <div className='note_point'>
                            <ul style={{ listStyle: 'number' }}>
                                <li>Incomplete Application Form Shall be Rejected/अपूर्ण आवेदन पत्र को अस्वीकार कर दिया जाएगा</li>
                                <li>Please Read all the instructions carefully before filling up the form/फॉर्म भरने से पहले कृपया सभी निर्देशों को ध्यान से पढ़ें</li>
                                <li>Application Signature is mandatory on the application form/
                                    आवेदन पत्र पर आवेदन हस्ताक्षर अनिवार्य है</li>
                                <li>Please make sure the details mentioned in the application form should be as same as the details to be filled up in the examination hall/कृपया सुनिश्चित करें कि आवेदन पत्र में उल्लिखित विवरण परीक्षा हॉल में भरे जाने वाले विवरण के समान होना चाहिए</li>
                                <li>Candidate application shold be forwarded by his/her controlling officer/head  of department/head  of the office only and then have to upload the same. forwarding from any other official on the application form will not be considered for valid candidate. pplication without "memo" and "date" will be rejected/उम्मीदवार के आवेदन को उसके नियंत्रण अधिकारी/विभागाध्यक्ष/कार्यालय प्रमुख द्वारा ही अग्रेषित किया जाना चाहिए और फिर उसे अपलोड करना होगा। आवेदन पत्र पर किसी अन्य अधिकारी से अग्रेषित करने पर वैध उम्मीदवार के लिए विचार नहीं किया जाएगा। "ज्ञापन" और "तारीख" के बिना आवेदन खारिज कर दिया जाएगा</li>
                            </ul>


                        </div>
                        <div className='applicant-photo p-5'>
                            <img style={{ width: 120, height: 100 }} src={!checkUrl(state.signatureImg) ? process.env.REACT_APP_FILE_URL + state.signatureImg : state.signatureImg} alt='applicant_photo' />
                            <p>आवेदक के हस्ताक्षर </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.Auth
    }
}

const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ApplicationForm);