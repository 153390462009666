import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { loaderEnd, loaderStart } from '../../components/loader';
import { getAdmitCard } from "../AdmitCard/api"
import ModalLayout from '../../components/modalLayout';
import Reregisrer from '../../Pages/Reregisrer';


import './Application.css'
import { downloadRes, downloadCertificate } from "./api";
const Application = (props) => {
    const navigate = useNavigate()
    const [approved, setApproved] = useState(false)
    const [reRegister, setReRegister] = useState(false)
    const [reRegisterConfirm, setReRegisterConfirm] = useState(false)
    const [allData, setAllData] = useState({})
    const [status, setStatus] = useState(null)

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        let res = await getAdmitCard()
        if (res.code) {
            setStatus(res.data.userDet?.status);
            setApproved(res.data.userDet?.is_approved == 1)
            setAllData(res.data)
        }
    }

    const updateData = async () => {
        // console.log(props.auth);
        loaderStart()
        if (props.auth.auth.data && props.auth.auth.data.aadhar) {
            let res = await downloadRes(props.auth.auth.data.aadhar)
            if (res.code && res.result.length > 0) {
                window.open(res.result[0].filepath, "_blank")
            }
            else {
                alert("No result available.")
            }
        }
        loaderEnd()
    }

    const handleCertificate = async () => {
        loaderStart()
        let res = await downloadCertificate()
        if (res.code && res.data.length) {
            // console.log(process.env.REACT_APP_CERTIFICATE+res.data[0].certificate_path);
            // fetch(process.env.REACT_APP_CERTIFICATE+res.data[0].certificate_path).then(response => {
            //     response.blob().then(blob => {
            //         // Creating new object of PDF file
            //         const fileURL = window.URL.createObjectURL(blob);
            //         // Setting various property values
            //         let alink = document.createElement('a');
            //         alink.href = fileURL;
            //         alink.download = 'SamplePDF.pdf';
            //         alink.click();
            //     })
            // })
            window.open(process.env.REACT_APP_CERTIFICATE + res.data[0].certificate_path, "_blank")
        }
        else {
            alert("No certificate available.")
        }
        loaderEnd()
    }

    return (
        <>
            <div className="card  application-box">
                {
                    status === "INACTIVE"
                        ?
                        <div className='disqualified'>
                            You account has been disabled.
                        </div>
                        : status === "ACTIVE" ?
                            <>
                                <div className="card-header">
                                    Please Read the Instruction Carefully / सावधानी से निर्देश पढ़ें
                                </div>

                                <div className='application-body row'>
                                    <div className='col-md-6'>
                                        <div className="card">
                                            <div className="card-header card-header-custom">
                                                Fill in Your Application
                                            </div>
                                            <div className="card-body">
                                                {/* <h5 className="card-title">Fill in Your Application</h5> */}
                                                <p className="card-text">नोट- आप अभ्यास सत्र में सम्मिलित होने के उपरांत या सीधे ही इस बटन को Click कर वास्तविक परीक्षा में शामिल होने के लिए आवेदन पत्र भर सकते हैं।.</p>
                                                <Link to="/application-form" className="btn btn-primary"><i className='fa fa-paper-plane'></i> &nbsp;Apply</Link>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        approved
                                            ?
                                            <div className='col-md-6'>
                                                <div className="card">
                                                    <div className="card-header card-header-custom">
                                                        Apply for exam
                                                    </div>
                                                    <div className="card-body">
                                                        <p className="card-text">नोट- आप अभ्यास सत्र में सम्मिलित होने के उपरांत या सीधे ही इस बटन को Click कर वास्तविक परीक्षा में शामिल होने के लिए आवेदन पत्र भर सकते हैं।.</p>
                                                        <Link to="/exam" className="btn btn-primary"><i className='fa fa-paper-plane'></i> &nbsp;Apply</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            : null

                                    }

                                    <div className='col-md-6'>
                                        <div className="card ">
                                            <div className="card-header card-header-custom">
                                                Practice Exam Slot Booking
                                            </div>
                                            <div className="card-body">
                                                {/* <h5 className="card-title">Practice Exam Slot Booking</h5> */}
                                                <p className="card-text">नोट- राजस्व पर्षद के द्वारा परीक्षार्थियों को यह सुविधा प्रदान की जा रही है कि यदि वे चाहें तो किसी भी विषय के लिए वे अभ्यास-सत्र को चुन कर उस विषय के लिए अभ्यास कर सकते हैं। कम्प्यूटर के समक्ष उपस्थित होकर चयनित विषय के Question को हल करेंगे यह कार्य मात्र अभ्यास के लिए है ताकि वें इस नई पद्वति से भलि-भांति अवगत हो जाये।.</p>
                                                <a href="https://mockbor.codebuckets.in" rel="noreferrer" target='_blank' className="btn btn-primary"><i className='fa fa-calendar'></i> &nbsp;Practice</a>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        allData?.certificateDet?.length > 0 && <div className="card col-sm-6">
                                            <div className="card-header card-header-custom">
                                                View Certificate
                                            </div>
                                            <div className="card-body">
                                                <p className="card-text">You can view your Certificate. Click here to</p>
                                                <a href={process.env.REACT_APP_CERTIFICATE + allData?.certificateDet[0].certificate_path} target="_blank" className="btn btn-primary"><i className='fa fa-download'></i> &nbsp;Download Certificate</a> &nbsp;
                                            </div>
                                        </div>
                                    }
                                    {allData?.userDet?.reregister_requested === "YES" && <div className="card col-sm-6">
                                        <div className="card-header card-header-custom">
                                            Re Register
                                        </div>
                                        <div className="card-body">
                                            <p className="card-text">Please click here to re register</p>
                                            <a onClick={() => setReRegisterConfirm(true)} target="_blank" className="btn btn-primary"> &nbsp;Click Here</a> &nbsp;
                                        </div>
                                    </div>}
                                </div>
                                <div className='application-body'>
                                    {/* <div className="card col-sm-6  card1">
                        <div className="card-header card-header-custom">
                            Practice Exam Slot Booking
                        </div>
                        <div className="card-body">
                            <p className="card-text">नोट- राजस्व पर्षद के द्वारा परीक्षार्थियों को यह सुविधा प्रदान की जा रही है कि यदि वे चाहें तो किसी भी विषय के लिए वे अभ्यास-सत्र को चुन कर उस विषय के लिए अभ्यास कर सकते हैं। कम्प्यूटर के समक्ष उपस्थित होकर चयनित विषय के Question को हल करेंगे यह कार्य मात्र अभ्यास के लिए है ताकि वें इस नई पद्वति से भलि-भांति अवगत हो जाये।.</p>
                            <a href="https://mockbor.silicontechlab.com/practice_test.php" rel="noreferrer" target='_blank' className="btn btn-primary"><i className='fa fa-calendar'></i> &nbsp;Practice</a>
                        </div>
                    </div> */}
                                    {/* <div className="card">
                        <div className="card-header card-header-custom">
                            Download your Admit Card
                        </div>
                        <div className="card-body">
                            <p className="card-text">You Can Download Your Admit Card from here. Click on the link below, it will redirect you to the list of subjects. You can view all the admit card from there</p>
                            <Link to="/exam" className="btn btn-primary"><i className='fa fa-download'></i> &nbsp;Download</Link>
                        </div>
                    </div> */}

                                    {/* <div className="card col-sm-6">
                        <div className="card-header card-header-custom">
                            View Certificate / Result
                        </div>
                        <div className="card-body">
                            <p className="card-text">You can view your Certificate / Result. Click here to</p>
                            <a href="#" onClick={() => handleCertificate()} className="btn btn-primary"><i className='fa fa-download'></i> &nbsp;Download Certificate</a> &nbsp;
                            <a href="#" onClick={() => updateData()} className="btn btn-primary"><i className='fa fa-download'></i> &nbsp;Download Result</a>
                        </div>
                    </div> */}
                                </div>
                            </>
                            : <div className='disqualified'>

                            </div>
                }

            </div>
            {
                reRegister
                    ?
                    <ModalLayout
                        modal={reRegister}
                        title="Re-registration"
                        size="xl"
                        toggle={() => setReRegister(false)}
                    >
                        <Reregisrer
                            allData={allData?.userDet}
                            toggle={() => navigate("/application-form")}
                        />
                    </ModalLayout>
                    : null
            }
            {
                reRegisterConfirm
                    ?
                    <ModalLayout
                        modal={reRegisterConfirm}
                        title="Confirmation"
                        toggle={() => setReRegisterConfirm(false)}
                    >
                        <div>
                            <strong>
                                All previous data and subject credits will be archived on re-registration.
                            </strong>
                        </div>
                        <br />
                        <div style={{ textAlign: "center" }}>
                            <button onClick={() => {
                                setReRegisterConfirm(false)
                                setReRegister(true)
                            }} className="btn btn-primary">Confirm</button>
                        </div>
                    </ModalLayout>
                    : null
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.Auth
    }
}

export default connect(mapStateToProps)(Application);
