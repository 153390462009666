import React from 'react'
import './application-format.css'
const ApplicationFormat = () => {
  return (
    <>
      <div className='application-format container-fluid' id='application-format'>
        <center>
          <b>
            <h4>केंद्रीय परीक्षा समिति बिहार </h4>
            <h4>आवेदन पत्र </h4>
            <p>( कृपया आवेदन पत्र भरते समय सूचनाओं को ध्यान से पढ़े )</p>
          </b>
        </center>
        <div className='applicant-detail-box '>
          <div className='applicant-detail '>
            <ul style={{ listStyle: 'number' }}>
              <li>Applicant Name/आवेदक का नाम :</li>
              <li>Date Of Birth/जन्म तिथि :</li>
              <li>Service/सेवा :</li>
              <li>Class/श्रेणी:</li>
              <li>Office Address/कार्यालय पता :</li>
              <li>Designation/पद नाम :</li>
              <li>Department/विभाग :</li>
              <li>Place/जगह :</li>
              <li>District/जिला :</li>
              <li>Employe Id: </li>
            </ul>
            <ul style={{ listStyle: 'none' }}>
              <li>Anchal Kumar</li>
              <li>11-03-1992</li>
              <li>Indian Administrative Service</li>
              <li>Others including general</li>
              <li>Patna , Bihar , 123456 </li>
              <li>SDFS </li>
              <li>SDV </li>
              <li>SAAS</li>
              <li>Patna </li>
              <li>SAD</li>
            </ul>

          </div>

          <div className='applicant-photo p-5'>
            <img src='https://picsum.photos/200' alt='applicant_photo' />
          </div>
        </div>
        <div className='appliaction_second_section'>
          <p>Application Number/आवेदन संख्या </p>
          <p>Email Id:/ईमेल</p>
          <p>Mobile Number/मोबाइल </p>
        </div>

        <div className='appliaction_memo_section'>

          <p>Memo/ज्ञापांक &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>Date/दिनांक </span> </p>
          <p>अग्रसारित नियंत्री पदाधिकारी / कार्यालय प्रधान का हस्ताक्षर ______________________</p>
          <p>Designation/पद नाम ___________________ </p>
          <p>Address/पता___________________ </p>
          <p>Mobile Number/मोबाइल ___________________ </p>
          <br />
          <p style={{ textAlign: 'right' }}>Office Seal/कार्यालय मुहर</p>
        </div>


        <div className='notice_section'>
          <p>Note/नोट </p>
          <div className='note_point'>
            <ul style={{ listStyle: 'number' }}>
              <li>Incomplete Application Form Shall be Rejected/अपूर्ण आवेदन पत्र को अस्वीकार कर दिया जाएगा</li>
              <li>Please Read all the instructions carefully before filling up the form/फॉर्म भरने से पहले कृपया सभी निर्देशों को ध्यान से पढ़ें</li>
              <li>Application Signature is mandatory on the application form/
                आवेदन पत्र पर आवेदन हस्ताक्षर अनिवार्य है</li>
              <li>Please make sure the details mentioned in the application form should be as same as the details to be filled up in the examination hall/कृपया सुनिश्चित करें कि आवेदन पत्र में उल्लिखित विवरण परीक्षा हॉल में भरे जाने वाले विवरण के समान होना चाहिए</li>
              <li>Candidate application shold be forwarded by his/her controlling officer/head  of department/head  of the office only and then have to upload the same. forwarding from any other official on the application form will not be considered for valid candidate. pplication without "memo" and "date" will be rejected/उम्मीदवार के आवेदन को उसके नियंत्रण अधिकारी/विभागाध्यक्ष/कार्यालय प्रमुख द्वारा ही अग्रेषित किया जाना चाहिए और फिर उसे अपलोड करना होगा। आवेदन पत्र पर किसी अन्य अधिकारी से अग्रेषित करने पर वैध उम्मीदवार के लिए विचार नहीं किया जाएगा। "ज्ञापन" और "तारीख" के बिना आवेदन खारिज कर दिया जाएगा</li>
            </ul>

            <div className='applicant-photo p-5'>
              <img src='https://picsum.photos/100' alt='applicant_photo' />
              <p>आवेदक के हस्ताक्षर </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ApplicationFormat