import moment from 'moment';

export const dateStandard = (date) => {
    return moment(date).format("DD-MM-YYYY");
}

export const dateObject = () => {
    return moment();
}

export const dateDev = (date) => {
    return moment(date).format("YYYY-MM-DD");
}

export const dateBasicFormant = (date) => {
    var _date = moment(date, "YYYY-MM-DD");
    return _date._d;
}


export const dayInFuture = (dayNum) => {
    const sundays = [];
    var sunday = new Date()
    sunday.setDate(sunday.getDate() + dayNum - sunday.getDay());
    for (var i = 0; i < 55; i++) {
        // console.log(sunday.toLocaleString());
        sundays.push(new Date(sunday.getTime()));
        sunday.setDate(sunday.getDate() + 7);
    }
    return sundays;

}

export const addDate = (date, day) => {
    return date ? moment(new Date(date)).add(day, 'days').format("DD-MM-YYYY") : "";
}