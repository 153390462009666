import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux';
import swal from 'sweetalert';

import { updateDocument, getDocument } from "./api";
import { loaderEnd, loaderStart } from '../../components/loader';
import Wizard from '../Wizard';
import { checkUrl } from "../../shared/strings"

const Step2 = (props) => {
    const navigate = useNavigate()
    // const showAlert = () => {
    //   swal("Application Form Submitted Successfully", "आपने अपना आवेदन सफलता पूर्वक समपर्पित कर दिया है | Your Application is forwarded for verification. you will be informed shortly whether it is complete or some more information required. You will be able to book your slot once your application is complete and found to be Ok ", "success")
    //   setTimeout(() => {
    //     navigate('/step3')
    //   }, 2000);
    // }
    const [migrated, setMigrated] = useState(0)

    const [state, setState] = useState({
        document: "",
        documentPath: "",
    })
    const [status, setStatus] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const handleChange = (e) => {
        delete state.documentPath;
        let _state = { ...state }
        _state[e.target.name] = e.target.files[0]
        setState(_state)
    }

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        loaderStart()
        let res = await getDocument();
        console.log(res);
        if (res.code == 1) {
            if (res.data.status == "INACTIVE") {
                navigate("/application")
            }
            setState({
                documentPath: res.data?.document
            })
            if (res.data?.migrated)
                setMigrated(1)
            setDisabled(props.auth.wizardStatus?.document == "filled" ? true : false)
            if (res.data?.document) {
                setStatus(true)
            }
        }
        loaderEnd()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (props.auth.wizardStatus.application == "new") {
            swal("Please complete step 1 to proceed.", "", "error")
        }
        else {
            loaderStart()
            let formData = new FormData();
            for (let [key, val] of Object.entries(state)) {
                if (val)
                    formData.append(key, val);
            }
            if (!state.document) {
                formData.append("document", state.documentPath);
            }
            let res = await updateDocument(formData)
            if (res.code == 1) {
                swal("Application Form Submitted Successfully", "आपने अपना आवेदन सफलता पूर्वक समपर्पित कर दिया है | Your Application is forwarded for verification. you will be informed shortly whether it is complete or some more information required. You will be able to book your slot once your application is complete and found to be Ok ", "success")
                    .then((value) => {
                        if (props.auth.wizardStatus?.document != "disabled")
                            navigate('/application')
                    });
            }
            else {
                swal(res.message, "", "error")
            }
            loaderEnd()
        }
    }

    return (
        <>
            <div className="card  application-box">
                <Wizard
                    document={status}
                    title="Document Upload"
                    redirect='/application-form'
                />

                <form onSubmit={handleSubmit}>
                    <div id="applications" className='application-body application-form-box'>
                        <div className='card application-form'>
                            <center> <h4> Documents Details</h4></center>
                            <p align="center"><i style={{ color: 'red', fontSize: '16px' }} s>नोट - आवेदक अनिवार्यत: अपने नियंत्री पदाधिकारी / कार्यालय प्रधान से ही आवेदन अग्रसारित कराकर ही अपलोड करेंगे |  किसी अन्य पदाधिकारी द्वारा अग्रसारित आवेदन पत्र मान्य नहीं होगा | बिना ज्ञापांक और दिनांक के अग्रसारित आवेदन पत्र अस्वीकृत कर दिया जायेगा </i></p>
                            <div className="row g-3">
                                <div className="row  mt-3">
                                    <label for="inputEmail3" className="col-sm-4 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i>Application Form</label>
                                    <div className="col-sm-6">
                                        <input
                                            name="document"
                                            onChange={(e) => handleChange(e)}
                                            type="file"
                                            className="form-control"
                                            id="inputEmail3"
                                            required={state.documentPath ? false : true}
                                            accept="application/pdf"
                                            disabled={disabled}
                                        />
                                    </div>
                                    {
                                        state.documentPath
                                            ?
                                            <a target="_blank" href={checkUrl(state.documentPath) ? state.documentPath : process.env.REACT_APP_FILE_URL + state.documentPath}>View file</a>
                                            : null
                                    }
                                </div>
                                <p align="center"><i style={{ color: 'red', fontSize: '16px' }} s>नोट -
                                    फ़ाइल का साइज़ 4KB से 5MB के बीच होना चाहिए ।</i></p>
                            </div>
                        </div>
                    </div>
                    {
                        props.auth.wizardStatus?.document == "new"
                            ?
                            <div className="d-grid gap-2">
                                <button className="btn btn-primary" type="submit"><i className="fa fa-paper-plane"></i>&nbsp;Upload</button>
                            </div>
                            : null
                    }

                </form>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.Auth
    }
}

export default connect(mapStateToProps)(Step2);
