import * as actionTypes from "./actionTypes";

const initialState = {
    auth: null,
    wizardStatus:{
        application:"new",
        document:"new",
        updateCenter:"disabled"
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_AUTH_DATA:
            return {
                ...state,
                auth: action.payload,
            };
            case actionTypes.UPDATE_FORM_STATUS:
            return {
                ...state,
                wizardStatus: action.payload,
            };
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default reducer;
