import React, { useState, useEffect } from 'react'
import * as APIs from "./api";
import Table from 'react-bootstrap/Table';

import './certificateCheck.css'
const CertificateCheck = () => {

    const [cert, setCert] = useState("")
    const [data, setData] = useState(null)

    const getData = async (e) => {
        e.preventDefault()
        let res = await APIs.getCertInfo({
            "certificateNumber": cert
        })
        if (res.code && res.data) {
            setData(res.data)
        }
        else {
            alert("Incorrect certificate number.")
        }
    }

    return (
        <>
            <div className='container certificate-container'>
                <div className='card certificate-card'>
                    <form onSubmit={getData}>
                        <div class="row g-3 align-items-center">
                            <div class="col-auto">
                                <label for="inputPassword6" class="col-form-label">Certificate Number: </label>
                            </div>

                            <div class="col-auto">
                                <input
                                    required
                                    value={cert}
                                    onChange={(e) => setCert(e.target.value)}
                                    type="text" id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline" />
                            </div>
                            <div class="col-auto">
                                {/* <span id="passwordHelpInline" class="form-text"> */}
                                <button type="submit" className='p-2 search_button'><i className='fa fa-search'></i>Search</button>
                                {/* </span> */}
                            </div>

                            {
                                data && <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Department</th>
                                            <th>Cadre</th>
                                            <th>Certificate Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{data.name}</td>
                                            <td>{data.department}</td>
                                            <td>{data.cadre}</td>
                                            <td>{data.certificateNumber}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            }


                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default CertificateCheck