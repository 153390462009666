import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

import { Link, useNavigate } from 'react-router-dom';
import { getAdmitCard, getPaymentInfo } from "../AdmitCard/api"
import { updateWizardStatus } from '../../store/auth/actions'
import help from '../../assests/pdf/Departmental Exam User Manual.pdf'
import { checkStatus } from "../../shared/commonFunction";

const Wizard = (props) => {

    const [payment, setPayment] = useState(false)

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        let payment = await getPaymentInfo()

        if (payment.data) {
            setPayment(payment.data)
        }
        let res = await getAdmitCard()
        if (res.code) {
            // console.log(res.data.user_det);
            let wizard = {
                application: checkStatus(res.data?.userDet, "passport"),
                document: checkStatus(res.data?.userDet, "document"),
                updateCenter: res.data.userDet?.is_approved == 1 ? "new" : "disabled",
            }
            props.updateWizardStatus(wizard)
        }


    }

    return (
        <>
            <div className='tab_button_group'>
                <Link to='/application-form' className='col-sm-2'>
                    <button style={props.auth.wizardStatus?.application == "filled" ? { backgroundColor: "green" } : {}} type="button" className="btn btn-primary">Step 1</button>
                </Link>
                <Link to='/step2' className='col-sm-2'>
                    <button style={props.auth.wizardStatus?.document == "filled" ? { backgroundColor: "green" } : {}} type="button" className="btn btn-primary">Step 2</button>
                </Link>
                {/* {
                    payment
                        ?
                        <Link to='/step3' className='col-sm-2'>
                            <button type="button" className="btn btn-primary">Step 3</button>
                        </Link>
                        : null

                } */}
            </div>
            <div className="card-header">
                <div className='card-header-container'>
                    <div>
                        <Link to={props.redirect}>
                            <button type="button" className="btn btn-warning btn-sm"><i className='fa fa-arrow-left'></i>&nbsp;Back</button>
                        </Link>
                    </div>
                    <div>
                        <i className='fa fa-laptop'></i>{props.title}
                    </div>
                    <div><button
                        // data-bs-toggle="modal"
                        // data-bs-target="#helpModal"
                        onClick={() => window.open(help, "_blank")}
                        type="button"
                        className="btn btn-warning btn-sm"
                    ><i className='fa fa-question'></i>&nbsp;Help</button>
                    </div>
                </div>
            </div>

            {/* modal */}
            <div class="modal fade" id="helpModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Help</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            In case of Any Application related query, Please contact your Pariksha Mitra.<br /><br />


                            Shivam Kumar, Pariksha Mitra - 9113123269<br />

                            Shakti kumar, Pariksha Mitra - 808349066<br />

                            Mayank Kumar, Pariksha Mitra - 8252373042<br />

                            Md. Khalid, Pariksha Mitra - 9795575905<br />
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.Auth
    }
}

const mapDispatchToProps = { updateWizardStatus }
export default connect(mapStateToProps, mapDispatchToProps)(Wizard);