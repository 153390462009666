import React from 'react'
import { Link } from 'react-router-dom'
import './footer.css'
export const Footer = () => {
  return (
    <footer className='footer'>
      <div className='custom-container'>
        <section className="">
          <div className="container text-center text-md-start mt-1">

            <div className="row mt-1">

              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-2">

                <h6 className="text-uppercase fw-bold">
                  BOR
                </h6>

                <p>
                  <i class="fa fa-copyright"></i>&nbsp;
                  Board of Revenue, Government of Bihar. Main Secretariat , Patna-800015
                </p>
              </div>
              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  Useful links
                </h6>
                <Link className='policy' to='/privacy-policy'>
                  <p>Privacy Policy</p>
                </Link>

                <Link className='policy' to='/refund-policy'>
                  <p>Refund Policy</p>
                </Link>

              </div>

              <div className="col-md-5 col-lg-3 col-xl-4 mx-auto mb-md-0 mb-4">

                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                <p><i className="fas fa-envelope me-3"></i>departmentalexamtechsprt@gmail.com</p>
                {/* <p>
                  <i className="fas fa-envelope me-3"></i>
                  ad-bipard-admin@bihar.gov.in
                </p> */}
                <p><i className="fas fa-phone me-3"></i>91-612-2452018</p>
                {/* <p><i className="fas fa-print me-3"></i>91-612-2452586</p> */}
              </div>

            </div>

          </div>
        </section>


      </div >
      <div className='footer_section2'>
        <div className="col-md-12 text-left text-md-right align-self-center">
          <center><p className="copyright-text">Copyright © 2023 <a className='copyright-link' href='https://codebuckets.in/' alt='codebucket'>Codebucket Solutions Pvt. Ltd.</a> All Rights Reserved.</p></center>
        </div>
      </div>
    </footer >
  )
}

export default Footer
