import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getTokenInfo } from "./api";

import './styles.css'
const ChapterPage = () => {

    const { ...params } = useParams()
    const [data, setData] = useState(null)

    console.log(params);

    useEffect(() => {
        if (params.id) {
            getData()
        }
    }, [])

    const getData = async () => {
        let data = await getTokenInfo(params.id)
        if (data.code) {
            setData(data.data)
        }
    }

    return (
        <>
            <div className='qrcode-check'>
                {
                    data
                        ?
                        <>
                            {
                                data.exist?.name && <h3 className='text-center'>Name: {data.exist?.name}</h3>
                            }

                            <h3 className='text-center mb-5'>{data.exist ? "Student exists" : "Student doesn't exists"}</h3>
                        </>
                        : null
                }
            </div>
        </>
    )
}

export default ChapterPage