
import './styles.css'
const QuestionBank = (props) => {


    return (
        <>
            <div style={{ padding: '5px 20px', margin: '3px 0px' }} class="card question-card">
                <h4 class="underline">Question {props.index}: </h4>
                <p class="questionBody individual_question"
                    dangerouslySetInnerHTML={{ __html: props.question.question }}
                ></p>
                <div class="options">
                    <ol class="options-list">
                        <li style={props.question.ans == 1 ? { background: "#ffb3b3" } : {}} class="option"
                            dangerouslySetInnerHTML={{ __html: props.question.option1 }}
                        ></li>
                        <li style={props.question.ans == 2 ? { background: "#ffb3b3" } : {}} class="option"
                            dangerouslySetInnerHTML={{ __html: props.question.option2 }}
                        ></li>
                        <li style={props.question.ans == 3 ? { background: "#ffb3b3" } : {}} class="option"
                            dangerouslySetInnerHTML={{ __html: props.question.option3 }}
                        ></li>
                        <li style={props.question.ans == 4 ? { background: "#ffb3b3" } : {}} class="option"
                            dangerouslySetInnerHTML={{ __html: props.question.option4 }}
                        ></li>
                    </ol>
                </div>
            </div>
        </>
    )
}

export default QuestionBank