import React, { useEffect} from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { updateAllotment } from "./api";
const Payment = () => {
    const navigate = useNavigate();
    const { ...params } = useParams()

    useEffect(() =>{
        update()
    },[])

    const update = async () => {
        let data = await updateAllotment(params.id)
    }

    return (
        <>
            <div className="card  application-box">
                <div className="card-header">
                    <div className='card-header-container'>
                        <div>
                            <Link to='/application'>
                                <button type="button" class="btn btn-warning btn-sm"><i className='fa fa-arrow-left'></i>&nbsp;Application</button>
                            </Link>
                        </div>
                        <div>
                            <i className='fa fa-credit-card'></i> Payment Info
                        </div>
                    </div>
                </div>
                <div id="applications" className='application-body application-form-box'>
                    <div className='card application-form'>
                        <center> <h4> Payment Details</h4></center>
                        {/* <p align="center"><i style={{ color: 'red', fontSize: '16px' }} s>Your payment is successful. Click the link below to view and download admit card</i></p> */}
                        <br />
                        <p align="center" style={{ color: 'red', }}>Your payment is failed. Please click on the link below try again</p>
                        <p align="center"><strong>Reason: </strong>Transaction authorization failed</p>
                    </div>
                </div>
                <div class="d-grid gap-2">
                    <button onClick={() => navigate("/exam")} class="btn btn-primary" type="button"><i className="fa fa-credit-card"></i>&nbsp;Go to payment page</button>
                </div>
            </div>
            <br />
            <br /><br />
        </>
    )
}

export default Payment