import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { getSubject } from "./api";
import { loaderStart, loaderEnd, notification } from "../../components/loader"
import './subjectpage.css';

const SubjectPage = () => {
    const allLanguage = [
        {
            name: 'English',
            code: "ENG"
        },
        {
            name: 'Hindi',
            code: "HINDI"
        }
    ]
    const [language, setLanguage] = useState("")
    const [subject, setSubject] = useState([])

    useEffect(() => {
        if (language) {
            getData()
        }
    }, [language])

    const getData = async () => {
        loaderStart()
        let data = await getSubject(language)
        if (data.code) {
            if (data.data.length)
                setSubject(data.data)
            else {
                notification(false, "No Subject Available")
            }
        }
        loaderEnd()
    }

    return (
        <>
            <div className='container-fluid card'>
                <h3 className='question-title'>Language</h3>
                <div className='chapter_container'>
                    {/* <div className="col-lg-3 col-xs-6 m-1 "></div> */}
                    {
                        allLanguage.map((l, i) => (
                            <div key={i} onClick={() => setLanguage(l.code)} className="col-lg-3 col-xs-6 m-1 ">
                                <div className="small-box-chapter bg-teal" style={{ backgroundColor: "#e4b365" }}>
                                    <div className="inner-chapter">
                                        <p className='language-box'>{l.name}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>

                {subject.length > 0 ? <h3 className='question-title'>Subject</h3> : <div style={{ marginTop: "12%" }}></div>}
                <div className='row'>
                    {/* <a href="question_bank_topic_search.php?lang_code=HINDI&amp;subject_code=CODE001" className="small-box-footer"> */}
                    {
                        subject.map((s) => (
                            <div className="col-lg-3 col-xs-6">
                                <Link target="_blank" to={'/chapter-page/' + s.subCode + "/" + language}>
                                    <div className="small-box bg-teal">
                                        <div className="inner">
                                            <h4 style={{ fontSize: '20px' }}>
                                                <b>{s.subCode}</b></h4>
                                            <p style={{ height: '70px', fontSize: '20px' }}>
                                            {s.subName} <strong>({s.total_count})</strong>
                                            </p>
                                        </div>
                                        <div className="icon">
                                            <i className="fa fa-book"></i>
                                        </div>
                                        <p style={{ textAlign: 'center', display: 'none' }}>
                                            <i className="fa fa-arrow-circle-right"></i>
                                        </p>
                                    </div>
                                </Link>
                            </div>
                        ))
                    }
                </div>

                {/* </a> */}

            </div>
        </>
    )
}

export default SubjectPage