import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import swal from 'sweetalert';
import Table from 'react-bootstrap/Table';
import Wizard from '../Wizard';
import { loaderEnd, loaderStart, notification } from '../../components/loader';
import { getSlots, getCenter, getAvailability, updateAvailability, initiatePayment, getAllSubjects, cancelExam } from "./api";
import { dayInFuture, dateStandard } from "../../shared/dateFn";
import ModalLayout from '../../components/modalLayout';
import ApplyModal from './applyModal';
import ResultModal from './resultModal';
import { getAdmitCard } from "../AdmitCard/api"


import "./styles.css";


const ExamSection = () => {
    const navigate = useNavigate()

    const [allSubject, setAllSubject] = useState([])

    const [allCenter, setAllCenter] = useState([])
    // const [allMonth, setAllMonth] = useState([])
    const [allSlotRange, setAllSlotRange] = useState([])
    const [disableDay, setDisableDay] = useState([])
    const [allHighlight, setAllHighlight] = useState([])
    const [modalResult, setModalResult] = useState(false)
    const [activeDate, setActiveDate] = useState(null)
    const [modalApply, setModalApply] = useState(false)
    const [modalCancel, setModalCancel] = useState(false)
    const [allData, setAllData] = useState(null)

    useEffect(() => {
        getDataApproved()
        getData()
        let sunday = dayInFuture(7)
        // let saturday = dayInFuture(6)
        setDisableDay([...sunday])
    }, [])

    const getDataApproved = async () => {
        loaderStart()
        let res = await getAdmitCard()
        if (res.code && res.data.userDet?.status != "INACTIVE") {
            if(res.data.userDet?.is_approved == 1){
                // navigate("/application")
            }
            else{
                navigate("/application")
            }
        }
        else{
            navigate("/application")
        }
        loaderEnd()
    }

    const getData = async () => {
        loaderStart()
        let subject = await getAllSubjects();
        if (subject.code == 1) {
            setAllData(subject.data);
            generateSubjectWiseData(subject.data)
        }
        loaderEnd()

        let res1 = await getCenter();
        if (res1.code == 1) {
            setAllCenter(res1.data)
        }
    }


    const generateSubjectWiseData = (data) => {
        let _rows = []
        data?.subjects?.map((s) => {
            let past = data?.pastResults?.filter((p) => p.subject_code == s.subject_code)
            let future = data?.futureExams?.filter((p) => p.subject_code == s.subject_code)
            let pendingAdmitCard = data?.pendingAdmitCard.filter(f => f.subject_code == s.subject_code)
            _rows.push({
                ...s,
                past,
                future,
                isEmptyAdmitCard:pendingAdmitCard.length ? false : true,
            })
        })
        setAllSubject(_rows)
    }

    const checkResult = (data) => {
        let final = false
        if (data.future.length == 0 && data.isEmptyAdmitCard) {
            final = true
            data.past?.map((d) => {
                if (d.exam_status == "PASS" && final) {
                    final = false;
                }
            })
        }
        return final;
    }
    // console.log(allSubject);

    const generateTable = () => {
        let _rows = []

        allSubject?.map((s) => (
            _rows.push(<tr>
                <td>{s.subName}</td>
                <td>{s.past.length}</td>
                <td>
                    {s.past.length > 0 &&
                        <i className="fa fa-clipboard-check" onClick={() => setModalResult(s)} aria-hidden="true"></i>
                    }
                </td>
                <td>
                    {s.future.length > 0 && <>
                        <i className="fa fa-address-card" onClick={() => navigate("/admit-card/" + s.subject_code)} aria-hidden="true"></i> &nbsp;
                        &nbsp;
                        {/* <i className="fa fa-trash" style={{ color: "red" }} onClick={() => setModalCancel(s.future[0])} aria-hidden="true"></i> */}
                    </>}
                </td>
                <td>
                    {checkResult(s) &&
                        <i onClick={() => setModalApply(s)} className="fa fa-pencil-alt" aria-hidden="true"></i>}
                </td>
            </tr>)
        ))
        return _rows;
    }

    const handleCancelExam = async () => {
        // console.log((modalCancel));
        loaderStart()
        let res = await cancelExam(allData?.userDet?.user_id, modalCancel.exam_id);
        if (res.code == 1) {
            getData()
            notification(true, "Success")
            setModalCancel(false)
        }
        else {
            notification(false, res.message)
        }
        loaderEnd()
    }

    return (
        <>
            <div className="card  application-box">
                <form className="row g-3">
                    <div id="applications" className='application-body application-form-box'>
                        <div className='card application-form overflowX'>
                            <div className='confirmation'>Please wait for 2 hours for slot confirmation.</div>
                            <center> <h4> Examination Details</h4></center>
                            {/* <p align="center"><i style={{ color: 'red', fontSize: '16px' }} >
                                Exam will be conducted only on saturdays of every month for without training candidates.
                                <br />Slots will be announced every two weeks for with training candidates. <br />Training will be conducted with minimum of 25 candidates else next slot wil be alloted. </i></p> */}

                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Subject</th>
                                        <th>Attempt</th>
                                        <th>Result</th>
                                        <th>Admit Card</th>
                                        <th>Apply</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {generateTable()}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </form>
            </div>
            {
                modalApply
                    ?
                    <ModalLayout
                        modal={modalApply}
                        title="Apply"
                        toggle={() => setModalApply(false)}
                    >
                        <ApplyModal
                            allCenter={allCenter}
                            data={modalApply}
                        />
                    </ModalLayout>
                    : null
            }
            {
                modalResult
                    ?
                    <ModalLayout
                        modal={modalResult}
                        title="Result"
                        toggle={() => setModalResult(false)}
                    >
                        <ResultModal
                            data={modalResult}
                        />
                    </ModalLayout>
                    : null
            }
            {
                modalCancel
                    ?
                    <ModalLayout
                        modal={modalCancel}
                        title="Cancel Slot"
                        toggle={() => setModalCancel(false)}
                    >
                        Do you want to cancel this slot?
                        <br />
                        <br />
                        <div style={{ textAlign: "center" }}>
                            <button onClick={() => handleCancelExam()} className="btn btn-danger" type="submit">Yes</button>
                        </div>

                    </ModalLayout>
                    : null
            }
        </>
    )
}

export default ExamSection