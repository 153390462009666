import React, { useEffect, useState, useRef } from "react";
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { useNavigate, useParams } from "react-router-dom";
import { getAdmitCard } from "./api";
import { dateStandard, addDate } from "../../shared/dateFn";
import { QRCodeSVG } from 'qrcode.react';
import classes from "./styles.module.scss";
import img from "../../assests/image/sign.png"
import { checkUrl } from "../../shared/strings"

const EachInfo = ({ data }) => {
    return (
        <div className={classes.eachText}>
            {
                data.map((d) => (
                    <>
                        <div>
                            <strong>{d.name}</strong>
                            <span>&nbsp;{d.value}</span>
                        </div>
                    </>
                ))
            }
        </div>
    )
}

const AdmitCard = () => {

    const componentRef = useRef();
    const [print, setPrint] = useState(false);
    const { ...params } = useParams();
    const handlePrint = () => {
        setPrint(true);
    }
    const closePrint = () => {
        setPrint(false);
    }

    const [admitData, setAdminData] = useState({})
    const [invalid, setInvalid] = useState(false)
    useEffect(() => {
        getData()
    }, [])

    // console.log(params);

    const navigate = useNavigate()

    const getData = async () => {
        let res = await getAdmitCard()
        if (res.code) {
            console.log(res.data);
            if (res.data.futureExams.length > 0) {
                let item = res.data.futureExams.filter((f) => f.subject_code == params.code)
                console.log(item);
                if (item.length) {
                    setAdminData({
                        ...item[0],
                        ...res.data.userDet
                    })
                }
                else {
                    setInvalid(true)
                }
            }
            else {
                setInvalid(true)
            }

        }
    }

    const getAddress = (address) => {
        if (address == 1) {
            return <>
                Bihar Institute of Public Administration & Rural Development<br />
                Walmi Campus,
                Phulwarisharif,<br />
                Patna 801 505
            </>
        }
        else {
            return <>
                BIPARD Gaya Campus<br />
                Kurshedra Near Gate No:-5<br />
                Gaya Bihar:-823003
            </>
        }
    }
    console.log(admitData);

    // console.log(`https://bipardexam.thecodebucket.com/check-qrcode/${admitData.uuid}`);
    return (
        <>
            {
                invalid
                    ?
                    <div style={{ width: "70%", margin: "auto", marginTop: "2rem", fontSize: 18 }}>
                        Sorry you have not created an exam. Please create and exam first to view admit card
                    </div>
                    :
                    <>

                        <div ref={componentRef} className={classes.section} style={print ? { width: "95%" } : {}}>
                            {
                                !print && <>
                                    <ReactToPrint
                                        onBeforeGetContent={() => {
                                            handlePrint()
                                            return Promise.resolve();
                                        }}
                                        onAfterPrint={() => {
                                            closePrint();
                                            return Promise.resolve();
                                        }}
                                        trigger={() => {

                                            return <button type="button" className={classes.printBtn} title="Print" >Print admit card</button>;
                                        }}
                                        content={() => componentRef.current}
                                    />&nbsp;&nbsp;
                                    <button type="button" onClick={() => navigate("/exam")} className={classes.printBtn} title="Print" >Go back</button>
                                    <br />
                                    {/* {
                                        admitData.with_training === "YES" && !admitData.start_time
                                            ?
                                            <div className={classes.withTrainingMessage}>Examination date and time will be updated soon.</div>
                                            : null
                                    } */}

                                </>
                            }

                            <div className={classes.title}>बिहार लोक प्रशासन एवं ग्रामीण विकास संस्थान, बिहार</div>
                            <div className={classes.subtitle}>Admit Card /  प्रवेश पत्र</div>
                            <div className={classes.infoSection}>
                                <div className={classes.textSection}>
                                    <EachInfo
                                        data={[{ name: "Roll No / रोल नं :", value: admitData.aadhar }, { name: "Date of birth / जन्म तिथि :", value: dateStandard(admitData.dob) }]}
                                    />
                                    <EachInfo
                                        data={[{ name: "Name / नाम :", value: admitData.name }, { name: "Category / कोटि :", value: admitData.category }]}
                                    />
                                    <EachInfo
                                        data={[{ name: "Address / पता :", value: admitData.office_address }, { name: "Service / सेवा का नाम :", value: admitData.service }]}
                                    />
                                </div>
                                <div className={classes.imgSection}>
                                    <img className={classes.photo} src={checkUrl(admitData.passport) ? admitData.passport : process.env.REACT_APP_FILE_URL + admitData.passport} />
                                </div>
                            </div>

                            <div className={classes.secondSection}>
                                <div className={classes.timeSection}>
                                    <div className={classes.key}>Subject / विषय</div>
                                    <div className={classes.value}>{admitData.subName}</div>

                                </div>
                                <div className={classes.timeSection}>

                                    <div className={classes.key}>Date / दिनांक</div>
                                    {
                                        admitData.with_training === "YES"
                                            ?
                                            <>
                                                {
                                                    admitData.exam_date
                                                        ?
                                                        <div className={classes.value}>{dateStandard(admitData.exam_date)}</div>
                                                        : <div className={classes.value}>{dateStandard(admitData.date)} - {addDate(admitData.date, 5)}</div>

                                                }
                                            </>
                                            :
                                            <div className={classes.value}>{dateStandard(admitData.exam_date)} </div>
                                    }
                                </div>
                                <div className={classes.timeSection}>
                                    {
                                        admitData.with_training === "YES" ?
                                            <>
                                                {
                                                    admitData.start_time && admitData.end_time
                                                        ?
                                                        <>
                                                            <div className={classes.key}>Time / समय</div>
                                                            <div className={classes.value}>{admitData.start_time} - {admitData.end_time}</div>
                                                        </>
                                                        : <>
                                                            <div className={classes.key}>Time / समय</div>
                                                            <div className={classes.value}>{"8:30 AM"} - {"6:15 PM"}</div>
                                                        </>
                                                }
                                            </>
                                            :
                                            <>
                                                <div className={classes.key}>Time / समय</div>
                                                <div className={classes.value}>{admitData.start_time} - {admitData.end_time}</div>
                                            </>
                                    }
                                </div>
                                {/* <div className={classes.timeSection}>
                                    <>
                                        <div className={classes.key}>Training /  प्रशिक्षण</div>
                                        <div style={{ textAlign: "center" }} className={classes.value}>{admitData.with_training}</div>
                                    </>
                                </div> */}
                            </div>



                            {/* Table */}
                            <div className={classes.tableTitle}>
                                उपर्युक्त परीक्षा के लिए परीक्षार्थी द्वारा निम्नलिखित अनुदेशों का पालन करना अनिवार्य है:-
                            </div>
                            <table className={classes.infoTable}>
                                <thead>
                                    <tr>
                                        <th>क्र.सं.</th>
                                        <th>परीक्षा के दौरान क्या करना है।(Do's)</th>
                                        <th>क्र.सं.</th>
                                        <th>परीक्षा के दौरान क्या नहीं करना हैं।(Don't)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td rowSpan={2}>1</td>
                                        <td rowSpan={2}>परीक्षा केन्द्र पर परीक्षा प्रारंभ होने से कम से कम 30 मिनट पहले उपस्थित रहें ताकि पहचान संबंधी औपचारिकताएं पूर्ण की जा सके आगामी परिक्षाओं में प्रतीक्षा सूची का प्रावधान किया जा रहा है, जो परीक्षार्थी 10 मिनट पूर्व तक उपस्थित नहीं होगें उनको परीक्षा में भाग लेने से वंचित करते हुए प्रतीक्षा सूची में शामिल नये परीक्षार्थी को मौका दिया जाएगा।</td>
                                        <td >1</td>
                                        <td>परीक्षा हॉल में मोबाइल / कैल्कुलेटर, Bluetooth एवं अन्य किसी भी प्रकार का इलेक्ट्रानिक्स उपकरण रखना एवं इनका उपयोग वर्जित है।</td>
                                    </tr>
                                    <tr>

                                        <td>2</td>
                                        <td>(क) परीक्षा कक्ष में परीक्षा के विषय से संबंधित व्याख्या एवं टिप्पणी रहित अधिनियम / नियम के पुस्तकों को छोड़कर किसी भी प्रकार का हाथ से लिखें चिट-पूर्जा, प्रिंट आउट, प्रश्न अधिकोष, गाइड एवं लैपटॉप का उपयोग पूर्णतः वर्जित है। (ख) परीक्षा कक्ष में प्रयोग में लायी जाने वाली पुस्तकों में प्रश्नों को पेन से चिन्हित एवं अध्यायों को ध्वजांकित कर लाना पूर्णतः वर्जित है।</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>परीक्षा से संबंधित Code हेतु निर्गत प्रवेश पत्र की Hard Copy (Printed Copy) एवं आधार कार्ड मूल रूप में अवश्य लाएँ ।</td>

                                        <td>3</td>
                                        <td>किसी भी दूसरे परीक्षार्थी से बात-चीत, शोर-गुल अथवा किसी भी प्रकार की सामग्री का आदान-प्रदान नहीं करना है।</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>जब तक राज्य में कोरोना संक्रमण का प्रभाव हैं तब तक परीक्षार्थी N 95 / Surgical Mask, Face Shield एवं Sanitizer के साथ परीक्षा में भाग लेगें।</td>
                                        <td>4</td>
                                        <td>परीक्षा कक्ष में नाश्ता, मादक पदार्थो का सेवन, धूम्रपान इत्यादि नहीं करना है।</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>बिपार्ड के कोरोना गाइडलाइन का पालन 4 नहीं करने पर परीक्षार्थी का परीक्षा कक्ष में प्रवेश वर्जित होगा।</td>
                                        <td>5</td>
                                        <td>परीक्षा के दौरान वीक्षक की अनुमति के बिना किसी भी शर्त पर बाहर नहीं जाना है।</td>
                                    </tr>
                                </tbody>

                            </table>

                            <div className={classes.footer}>
                                <div className={classes.center}>
                                    <strong>Exam Centre / परीक्षा केंद्र: &nbsp;&nbsp;</strong> {getAddress(admitData.center_id)}
                                </div>
                                <div className={classes.signSection}>
                                    <img className={classes.sign} src={img} />
                                    <div className={classes.name}>
                                        महानिदेशक, <br /> बिहार लोक प्रशासन एवं ग्रामीण विकास संस्थान, बिहार
                                    </div>
                                </div>
                            </div>
                            {/* <div style={{ textAlign: "center" }}>
                                <QRCodeSVG size={80} value={`https://bipardexam.thecodebucket.com/check-qrcode/${admitData.uuid}`} />
                            </div> */}
                        </div>
                    </>
            }

        </>
    )
}

export default AdmitCard