import * as actionTypes from "./actionTypes";

export const getDataAfterLogin = (data) => {
    return async (dispatch) => {

        dispatch(updateAuthData(data))
    }
};

export const updateAuthData = (data) => {
    return {
        type: actionTypes.UPDATE_AUTH_DATA,
        payload: data,
    };
};

export const updateWizardStatus = (data) => {
    return {
        type: actionTypes.UPDATE_FORM_STATUS,
        payload: data,
    };
};


export const getDataFromLocal = () => {
    return async (dispatch) => {
        let data = localStorage.getItem("BIPARD-registration") ? JSON.parse(localStorage.getItem("BIPARD-registration")) : null;
        if (data) {
            dispatch(updateAuthData(data))
        }
    }
};

export const onLogout = (navigate) => {
    return async (dispatch) => {
        localStorage.removeItem("BIPARD-registration")
        dispatch(updateAuthData(null))
        navigate("/")
    }
}
