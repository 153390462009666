import React, { useState, useEffect } from "react"
import Table from 'react-bootstrap/Table';
import { dateStandard } from "../../shared/dateFn"
import { saveAs } from 'file-saver'

const ApplyModal = (props) => {
    console.log(props);

    return (
        <div className="row g-3">
            {
                props.data?.past?.length > 0
                    ?
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Subject</th>
                                <th>Exam Date</th>
                                {/* <th>Exam Time</th> */}
                                <th>Status</th>
                                <th>Download</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.data?.past?.map((p) => (
                                    <tr>
                                        <td>{p.subName}</td>
                                        <td>{dateStandard(p.exam_date)}</td>
                                        {/* <td>{p.end_time}</td> */}
                                        <td>{p.exam_status}</td>
                                        <td>
                                            {p.result_path && <i onClick={() => saveAs(process.env.REACT_APP_FINAL_RESULT + p.result_path, p.result_path)} className="fa fa-eye" aria-hidden="true"></i>}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                    : <div>No Result available</div>
            }

        </div>
    )
}

export default ApplyModal;