import { Button, Modal } from 'react-bootstrap';

const ModalLayout = (props) => {
    console.log(props);
    return (
        <Modal show={props.modal} size={props.size} onHide={props.toggle}>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.children}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.toggle}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );

}

export default ModalLayout;
