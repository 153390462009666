import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import moment from 'moment-timezone';

import DatePicker from "../../components/datePicker";

import { getSlots, getCenterPerSubject, getAvailability, updateAvailability, initiatePayment, getAllSubjects } from "./api";
import { loaderEnd, loaderStart } from '../../components/loader';


const Legend = (props) => {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: 10, height: 10, backgroundColor: props.color }}></div>
            &nbsp;{props.title}
        </div>
    )
}

const ApplyModal = (props) => {
    const [state, setState] = useState({
        slot_id: null,
        date: "",
        center_id: "",
    })
    const [activeDate, setActiveDate] = useState(null)
    const [allSlot, setAllSlot] = useState([]);
    const [allCenter, setAllCenter] = useState([]);
    const navigate = useNavigate();
    // const [allHighlight, setAllHighlight] = useState([])

    const updateData = (e, name) => {
        // console.log(name);
        let _state = { ...state }
        if (name === "center_id") {
            _state["date"] = "";
        }

        if (name === "date") {
            _state[name] = e;
        }
        else
            _state[e.target.name] = e.target.value;
        // console.log(_state);
        setState(_state);
    }

    useEffect(() => {
        const _ = async() =>{
            let res =  await getCenterPerSubject(props.data?.subject_code);
            if(res.code){
                setAllCenter(res.data)
            }
        }
        _()
    }, [props.data?.subject_code])

    useEffect(() => {
        if (state.center_id) {
            getAvail();
        }
    }, [state.center_id])

    const getAvail = async () => {
        let res = await getAvailability(state.center_id)
        // console.log(res);
        if (res.code) {
            let _date = res.data.map((r) => {
                let _d = moment(r.date).tz("Asia/Kolkata").format();
                _d = new Date(_d);
                return _d
            })
            console.log(_date);
            setActiveDate(_date)
        }
        else {
            setActiveDate([])
            setAllSlot([])
        }
    }

    useEffect(() => {
        if (state.center_id && state.date) {
            getSlotsData();
        }
    }, [state.center_id, state.date])

    const getSlotsData = async () => {
        let res = await getSlots(state.center_id, 0, state.date)
        if (res.code) {
            setAllSlot(res.data)
        }
        else {
            setAllSlot([])
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (state.date && state.center_id) {
            finalSubmit()
        }
        else {
            swal("Please select date, center and slot to proceed", "", "error")
        }
    }

    const getSlot = (id) => {
        let filter = allSlot.filter((a) => a.id == id);
        // console.log(allSlot, filter, id);
        if (filter.length) {
            return filter[0].start_time + " - " + filter[0].end_time
        }
    }

    const finalSubmit = async () => {
        // loaderStart()

        let data = {
            "center_id": state.center_id,
            "slot_id": state.slot_id,
            date: state.date,
            "with_training": "NO",
            subject_code: props.data.subject_code
        }
        // console.log(data);
        let res = await updateAvailability(data)
        // console.log(res);
        if (res.code == 1) {
            // showAlert(res.data)
            if (res.data.paymentStatus) {
                makePayment(res.data.allotmentId)
            }
            else {
                navigate("/payment-response/" + res.data.allotmentId)
            }
        }
        else {
            swal(res.message, "", "error")
        }

        loaderEnd()
    }

    const showAlert = (allotmentId) => {
        swal("Slot Booking Submitted Successfully", "", "success")
            .then((value) => {
                // navigate('/payment')
                makePayment(allotmentId)
            });
    }

    const makePayment = async (allotmentId) => {
        loaderStart()
        let id = Math.floor(Date.now());
        let amt = state.category == 1 ? 350000 : 110000
        window.location.href = process.env.REACT_APP_API_URL + "form/payment?amount=" + amt + "&hdnOrderID=" + id + "&trnRemarks=&allotmentId=" + allotmentId
        loaderEnd()
    }

    // console.log(state);

    return (
        <form className="row g-3" onSubmit={handleSubmit}>
            <div id="applications" className='application-body application-form-box'>
                <div className="row  mt-3">
                    <label for="inputEmail3" className="col-sm-4 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i>Please Select Center</label>
                    <div className="col-sm-6">
                        <select
                            className="form-select"
                            id="autoSizingSelect"
                            name={"center_id"}
                            required
                            value={state.center_id}
                            onChange={(e) => updateData(e, 'center_id')}
                        >
                            <option selected value=''>Select Center</option>
                            {
                                allCenter?.map((a) => (
                                    <option value={a.id}>{a.centre}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                {
                    state.center_id &&
                    <>
                        {activeDate && <div className="row  mt-3">
                            <label for="inputEmail3" className="col-sm-4 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i>Please Select Your Examination Date</label>
                            <div className="col-sm-6">
                                <DatePicker
                                    name={"date"}
                                    date={state.date ? new Date(state.date) : ""}
                                    required={false}
                                    handleDateChange={(name, e) => updateData(e, name)}
                                    // excludeDates={disableDay}
                                    // maxDate={activeDate ? new Date(activeDate) : null}
                                    // minDate={new Date("2023-01-16")}
                                    // minDate={activeDate ? new Date(activeDate) : null}
                                    includeDates={activeDate}
                                />
                                {/* <input type="date" className="form-control" id="inputEmail3" /> */}
                            </div>
                        </div>}
                        <div className="row  mt-3">
                            <label for="inputEmail3" className="col-sm-4 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i>Please Select Your Examination Slot</label>
                            <div className="col-sm-6">
                                <select
                                    className="form-select"
                                    id="autoSizingSelect"
                                    name={"slot_id"}
                                    required
                                    value={state.slot_id ? state.slot_id : ''}
                                    onChange={(e) => updateData(e)}
                                >
                                    <option value='' selected>Select Slot</option>
                                    {
                                        allSlot?.map((a) => (
                                            <option value={a.id}>{a.start_time} - {a.end_time}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="legend-section">
                            <Legend color="green" title="Available" />
                            <Legend color="red" title="Full" />
                        </div>
                    </>
                }
            </div>
            <div className="d-grid gap-2">
                <button className="btn btn-primary" type="submit"><i className="fa fa-next"></i>&nbsp;Submit and Pay Now</button>
            </div>
        </form>
    )
}

export default ApplyModal;