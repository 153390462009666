import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../components/footer/Footer'
import Header from '../components/Header/Header'
import "./styles.css";
const Layout = () => {
  return (
    <div className='layout'>
      <Header />
      <Outlet />
      {/* <HomePage /> */}
      <Footer />
    </div>
  )
}

export default Layout